{
  "name": "smart-pensjon-web",
  "version": "1.153.3",
  "groupId": "no.storebrand.web.clients",
  "engines": {
    "node": ">=18.13",
    "pnpm": ">=9"
  },
  "jestSonar": {
    "reportPath": "coverage",
    "reportFile": "test-reporter.xml",
    "indent": 4
  },
  "scripts": {
    "ng": "ng",
    "start": "pnpm run curl-fms-pending && ng serve",
    "start:port-5000": "ng serve --port 5000 --disable-host-check",
    "start:behind-docker-compose": "ng serve --port 5000 --disable-host-check --configuration=behind-docker-compose",
    "hmr": "ng serve --configuration=hmr",
    "ng-high-memory": "node --max_old_space_size=16000 ./node_modules/@angular/cli/bin/ng",
    "build": "pnpm run curl-fms-active && tsc --noEmit && pnpm run ng-high-memory build --configuration production",
    "build:local": "pnpm run curl-fms-active && tsc --noEmit && pnpm run ng-high-memory -- build --configuration production",
    "bundle:stats": "ng build --configuration production --stats-json",
    "bundle:report": "webpack-bundle-analyzer dist/stats.json",
    "e2e": "echo 'haz no e2e!'",
    "test": "pnpm run curl-fms-active && ng test",
    "test:watch": "pnpm run curl-fms-active && ng test --watch",
    "test:path": "ng test --watch --test-path-pattern",
    "lint": "ng lint smart-pensjon-web",
    "lint:fix": "ng lint smart-pensjon-web --fix",
    "prettify": "prettier --list-different **/*.{js,json,ts,html,css,scss} !**/{dist,e2e,.vscode,.angular}/**",
    "prettify-write": "prettier --write **/*.{js,json,ts,html,css,scss} !**/{dist,e2e,.vscode,.angular}/**",
    "prettify-nix": "prettier --list-different '**/*.{js,json,ts,html,css,scss}' '!**/{dist,e2e,.vscode,.angular}/**'",
    "prettify-write-nix": "prettier --write '**/*.{js,json,ts,html,css,scss}' '!**/{dist,e2e,.vscode,.angular}/**'",
    "upload-sourcemaps-sentry": "sh upload-sentry-sourcemaps.sh",
    "curl-fms-active": "curl \"https://api.storebrand.no/open/content/texts/nyt?version=active&lang=no\" -H \"ClientId: nyt\" --output src/assets/fms/lang-no.json  && curl \"https://api.storebrand.no/open/content/texts/nyt?version=active&lang=en\" -H \"ClientId: nyt\" --output src/assets/fms/lang-en.json",
    "curl-fms-pending": "curl \"https://api.storebrand.no/open/content/texts/nyt?version=pending&lang=no\" -H \"ClientId: nyt\" --output src/assets/fms/lang-no.json  && curl \"https://api.storebrand.no/open/content/texts/nyt?version=pending&lang=en\" -H \"ClientId: nyt\" --output src/assets/fms/lang-en.json",
    "prepare": "is-ci || husky install",
    "type-check": "tsc --noEmit",
    "generate-typedefs-graph": "graphql-codegen",
    "generate-types-bank-account-rest": "./generate-typescript-from-rest-schema.sh https://t.stb.intra/gcp/bank-account-rest-api/v3/api-docs src/app/services/api/bank-account-rest-api.types.ts",
    "generate-types-benefits-rest": "./generate-typescript-from-rest-schema.sh https://t-benefit-api.enterprise.storebrand.no/v3/api-docs src/app/services/benefits-rest-api.types.ts",
    "postbuild": "node build-utils/brotli-compress.js"
  },
  "lint-staged": {
    "*.{ts,html}": [
      "eslint --ext .ts,.html"
    ],
    "*.{js,json,css,scss,md}": [
      "prettier --write"
    ]
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.5",
    "@angular/cdk": "^18.2.14",
    "@angular/common": "^18.2.5",
    "@angular/compiler": "^18.2.5",
    "@angular/core": "^18.2.5",
    "@angular/forms": "^18.2.5",
    "@angular/platform-browser": "^18.2.5",
    "@angular/platform-browser-dynamic": "^18.2.5",
    "@angular/router": "^18.2.5",
    "@apollo/client": "^3.11.8",
    "@floating-ui/dom": "1.2.6",
    "@graphql-tools/mock": "^9.0.0",
    "@graphql-tools/schema": "^10.0.2",
    "@sentry/angular-ivy": "^7.86.0",
    "@sentry/browser": "^7.86.0",
    "@sentry/integrations": "^7.86.0",
    "@sentry/tracing": "^7.86.0",
    "@sentry/types": "^7.86.0",
    "@storeblocks/accordion-ng": "^14.3.6",
    "@storeblocks/alert-ng": "^16.1.4",
    "@storeblocks/assets": "^3.9.2",
    "@storeblocks/breadcrumbs-ng": "^1.1.1",
    "@storeblocks/button-ng": "^15.8.0",
    "@storeblocks/card-info-ng": "^16.1.8",
    "@storeblocks/card-navigation-ng": "^17.0.1",
    "@storeblocks/checkbox-ng": "^14.8.3",
    "@storeblocks/chip-ng": "^15.10.1",
    "@storeblocks/form-ng": "^15.2.7",
    "@storeblocks/inline-alert-ng": "^14.5.0",
    "@storeblocks/link-list-ng": "^15.2.0",
    "@storeblocks/loading-indicator-dots-ng": "^16.3.1",
    "@storeblocks/loading-indicator-linear-ng": "^15.2.0",
    "@storeblocks/modal-ng": "^15.6.2",
    "@storeblocks/popover-ng": "^16.0.6",
    "@storeblocks/radio-buttons-ng": "^15.3.5",
    "@storeblocks/switch-ng": "^16.1.1",
    "@storeblocks/table-ng": "^15.1.2",
    "@storeblocks/tabs-ng": "^14.3.2",
    "@storeblocks/toast-ng": "^15.2.10",
    "@storeblocks/tooltip-ng": "^16.2.2",
    "@storebrand-digital/auth": "^1.13.2",
    "@storebrand-digital/common-datadog": "^2.1.5",
    "angular-in-memory-web-api": "^0.18.0",
    "apollo-angular": "^7.1.2",
    "core-js": "^3.34.0",
    "date-fns": "^2.30.0",
    "graphql": "^16.8.1",
    "highcharts": "^11.2.0",
    "highcharts-angular": "^4.0.0",
    "immer": "^10.0.3",
    "keycloak-js": "^21.1.2",
    "lodash-es": "^4.17.21",
    "lottie-web": "^5.12.2",
    "nouislider": "^15.7.1",
    "rxjs": "7.8.1",
    "shepherd.js": "^11.2.0",
    "tslib": "^2.6.2",
    "unleash-proxy-client": "^3.6.1",
    "util": "^0.12.5",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-builders/jest": "^18.0.0",
    "@angular-devkit/build-angular": "^18.2.5",
    "@angular-eslint/builder": "^18.3.1",
    "@angular-eslint/eslint-plugin": "^18.3.1",
    "@angular-eslint/eslint-plugin-template": "^18.3.1",
    "@angular-eslint/schematics": "^18.3.1",
    "@angular-eslint/template-parser": "^18.3.1",
    "@angular/cli": "^18.2.5",
    "@angular/compiler-cli": "^18.2.5",
    "@angular/language-service": "^18.2.5",
    "@angularclass/hmr": "3.0.0",
    "@commitlint/cli": "^18.6.0",
    "@commitlint/config-conventional": "^18.6.0",
    "@faker-js/faker": "^8.3.1",
    "@graphql-codegen/add": "^5.0.0",
    "@graphql-codegen/cli": "^5.0.0",
    "@graphql-codegen/typescript": "^4.0.1",
    "@graphql-tools/utils": "^10.0.11",
    "@types/jest": "^29.5.12",
    "@types/lodash-es": "^4.17.12",
    "@types/node": "^22.5.5",
    "@types/webpack-env": "^1.18.4",
    "@typescript-eslint/eslint-plugin": "8.8.1",
    "@typescript-eslint/parser": "8.8.1",
    "brotli": "^1.3.3",
    "commitizen": "^4.3.0",
    "compression-webpack-plugin": "^11.1.0",
    "cz-conventional-changelog": "^3.3.0",
    "eslint": "^8.57.1",
    "eslint-config-prettier": "^9.1.0",
    "eslint-plugin-destructure-depth": "^1.0.3",
    "eslint-plugin-fp": "^2.3.0",
    "eslint-plugin-import": "^2.29.0",
    "eslint-plugin-jsdoc": "^46.9.0",
    "eslint-plugin-prefer-arrow": "1.2.3",
    "eslint-plugin-prettier": "^5.1.3",
    "eslint-plugin-rxjs": "^5.0.3",
    "eslint-plugin-sonarjs": "^0.23.0",
    "husky": "^8.0.3",
    "is-ci": "^3.0.1",
    "jest": "^29.7.0",
    "jest-sonar-reporter": "^2.0.0",
    "lint-staged": "^15.2.2",
    "prettier": "^3.2.5",
    "sentry-testkit": "^5.0.8",
    "ts-jest": "^29.1.1",
    "ts-node": "^10.9.2",
    "typescript": "^5.5.4",
    "webpack-bundle-analyzer": "^4.10.1"
  },
  "config": {
    "commitizen": {
      "path": "./node_modules/cz-conventional-changelog"
    }
  }
}
