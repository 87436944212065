import { Injectable } from "@angular/core";
import { produce } from "immer";
import { combineLatestWith, first, from, ignoreElements, map, merge, mergeMap, Observable } from "rxjs";
import { IContext, IMutableContext, IVariant } from "unleash-proxy-client";
import { BehaviorStore } from "../utils/rxjs/store";
import { getIsNotNullable } from "../utils/utils";
import { KeycloakService } from "./keycloak.service";
import { UnleashClientWrapperService, UnleashContext } from "./unleash/unleash-client-wrapper.service";

export enum FeatureKey {
  PartialWithdrawalToggle = "smart-pensjon-web-partial-withdrawal",
  HideRevenueCampaign2024 = "smart-pensjon-web-hide-revenue-campaign",

  /** Used for testing the service without depending on an actual feature toggle */
  TestingToggle = "smart-pensjon-web-testing-toggle",
}

export type OverridableFeatureTogglesMap = Map<string, boolean | null>;

/**
 * When creating new feature toggles, use the following naming convention:
 *
 * ```ts
 * `smart-pensjon-web-${toggleName}`
 * ```
 * {@link https://app.unleash-hosted.com/storebrand/projects/retail-pension-web}
 */
@Injectable({
  providedIn: "root",
})
export class FeatureToggleService {
  private readonly featureToggleCache = new Map<FeatureKey, Observable<boolean>>();
  private readonly overridesSubject$: BehaviorStore<OverridableFeatureTogglesMap> = new BehaviorStore(new Map());
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public readonly overrides$: Observable<OverridableFeatureTogglesMap> = this.overridesSubject$.asObservable();

  constructor(
    private readonly keycloakService: KeycloakService,
    private readonly unleashClientWrapperService: UnleashClientWrapperService,
  ) {}

  public get(featureKey: FeatureKey): Observable<boolean> {
    if (!this.featureToggleCache.has(featureKey)) {
      const overriddenValue$ = this.overrides$.pipe(map((override) => override.get(featureKey)));
      const unleashValue$ = this.unleashClientWrapperService.isEnabledObservable(featureKey);

      const featureToggle$ = unleashValue$.pipe(
        combineLatestWith(overriddenValue$),
        map(([unleashValue, overridenValue]) => (getIsNotNullable(overridenValue) ? overridenValue : unleashValue)),
      );

      this.featureToggleCache.set(featureKey, featureToggle$);
    }

    return this.featureToggleCache.get(featureKey) as Observable<boolean>;
  }

  public isEnabled(toggleName: string): boolean {
    return this.unleashClientWrapperService.isEnabled(toggleName);
  }

  public getVariant(toggleName: FeatureKey): IVariant {
    return this.unleashClientWrapperService.getVariant(toggleName);
  }

  public setOverride(toggleName: string): void {
    const newValue = produce(this.overridesSubject$.getValue(), (draft) => {
      draft.set(toggleName, draft.has(toggleName) ? !draft.get(toggleName) : false);
    });
    this.overridesSubject$.next(newValue);
  }

  public getOverridableFeatureToggleMap(): Observable<OverridableFeatureTogglesMap> {
    return this.unleashClientWrapperService.getAllToggles().pipe(
      combineLatestWith(this.overrides$),
      map(([toggles, overrides]) =>
        toggles.reduce((togglesMap, toggle) => {
          const enabled = overrides.get(toggle.name) ?? toggle.enabled;
          return togglesMap.set(toggle.name, enabled);
        }, new Map()),
      ),
    );
  }

  public init(stopSignal$: Observable<void>): Observable<void> {
    const warmCache$ = from(Object.values(FeatureKey)).pipe(
      mergeMap((value) => this.get(value)),
      ignoreElements(),
    );
    const start$ = this.getUnleashContext().pipe(
      mergeMap((context) => this.unleashClientWrapperService.start(context)),
    );
    const stop$ = stopSignal$.pipe(
      first(),
      mergeMap(() => this.unleashClientWrapperService.stop()),
    );

    return merge(warmCache$, start$, stop$);
  }

  public getCurrentContext(): IContext {
    return this.unleashClientWrapperService.getContext();
  }

  public updateContext(context: IMutableContext): Promise<void> {
    return this.unleashClientWrapperService.updateContext(context);
  }

  public getUnleashContext(): Observable<UnleashContext> {
    // Pass the CMID as userID to Unleash
    return this.keycloakService.cmid.user$.pipe(
      first(),
      map((userId) => ({
        userId,
      })),
    );
  }
}
